import { ProductVariant } from "@medusajs/medusa"

const SKUsWithoutGiftWrapping = [
  "BS-HSAB",
  "BS-SSSB",
  "BS-PSBS",
  "WS-SHPIST",
  "WS-SNS",
  "WS-KS",
  "WS-CCR",
  "WS-KRK",
  "TS-SIS",
  "TS-CSK",
  "TS-KS",
  "TS-CLS",
  "TS-RR",
  "WBS-IB",
  "PSS-BAS",
  "PSS-HAS",
  "PSS-PES",
  "TD-WD",
  "TD-SP",
  "TD-HP",
  "TD-SD",
  "TD-RP",
  "DN-SG",
  "DN-SS",
  "DN-AS",
  "DN-WS",
  "DN-LOW",
  "DN-MEDIUM",
  "DN-HIGH",
  "DN-BY",
  "DN-JR",
  "BS-BR",
  "BS-JB",
  "BS-PG",
  "BS-PR",
  "BS-SG",
  "BS-SY",
  "BS-CW",
  "BB-BS",
  "CA-BU",
  "FP-DB",
  "FP-PB",
  "FP-WR",
  "FB-BKG",
  "FB-BEG",
  "FB-RDG",
  "FB-BL",
  "FB-GM",
  "FB-SW",
  "FB-CA",
  "FB-DB",
  "FB-DG",
  "MP-DB",
  "MP-FR",
  "PNW-BO",
  "PNW-DM",
  "LC-VEB",
  "LC-BCB",
  "LC-TSB",
  "LC-BOB",
  "LC-RBC",
  "LC-BJV",
  "LC-OGV",
  "LC-BLO",
  "LC-OBR",
  "LC-ROV",
  "LC-OUG",
  "LC-VEA",
  "LC-ORV",
  "LC-JAV",
  "LC-ROR",
  "LB-OW",
  "LB-CA",
  "LB-SS",
  "LB-NS",
  "LB-RB",
  "LB-GB",
  "JP-R1",
  "LC-BLA",
  "LC-VAC",
  "CA-BT",
  "TC-AC",
  "TC-BL",
  "TC-CL",
  "TC-DE",
  "TC-PO",
  "TC-SN",
  "TC-BLO",
  "TC-OAT",
  "UJ-PO",
  "UJ-SLA",
  "UJ-MA",
  "UJ-ST",
  "NA-PO",
  "NA-SLA",
  "NA-MA",
  "NA-ST",
  "BTK-CA",
  "BTK-FG",
  "BTK-KB",
  "BTK-LA",
  "BTK-MA",
  "BTK-RR",
  "SWSET-BS",
  "SWSET-HS",
  "SWSET-PL",
  "SWSET-PP",
  "SWSET-RB",
  "SWSET-TN",
  "PPK-AWW",
  "PPK-AMB",
  "PPK-AMP",
  "BTK-TEGRST",
  "BTK-SOBLST",
  "SWSET-PS",
  "SWSET-PPS",
  "PPK-HS",
  "PPK-IBS",
  "PPK-MPS",
  "BB-BL",
  "BB-WH",
  "BB-ISB",
  "BB-POR",
  "BB-WAN",
  "TT-LMS",
  "TT-MIS",
  "BM-KHM",
  "SWP-LMS",
  "SWT-LMS",
  "SWP-MIS",
  "SWT-MIS",
  "SWBS-LMS",
  "SWBS-MIS",
  "AU-DJ",
  "AU-RC",
  "AU-NCNB",
  "AU-NCTP",
  "TT-LMS",
  "AU-NS",
]

export const hasVariantGiftWrapping = (variant: ProductVariant): boolean => {
  const sku = variant?.sku

  if (
    !Boolean(variant) ||
    variant?.product?.is_giftcard ||
    sku === "TT-LS-100x180" ||
    sku === "TT-DS-100x180" ||
    sku === "TT-IS-100x180" ||
    sku.startsWith("DN-HIGH") ||
    sku.startsWith("DN-MEDIUM") ||
    sku.startsWith("DN-LOW") ||
    sku.startsWith("DN-WS") ||
    sku.startsWith("DN-AS") ||
    sku.startsWith("DN-SS") ||
    sku.startsWith("DN-SG") ||
    SKUsWithoutGiftWrapping.some((giftSetSKU) => sku.startsWith(giftSetSKU))
  ) {
    return false
  }

  return true
}
