/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from "@stripe/stripe-js"

const STRIPE_API_KEY =
  process.env.GATSBY_STRIPE_API_KEY ||
  "pk_test_c3dMJhzZWf3NNoCDFDlau7Q200F32OSyGS"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_API_KEY)
  }
  return stripePromise
}

export default getStripe
